import React  from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home"
import Contact from "./pages/Contact"
import Banner from "./images/banner.jpg"


function App() {
  return (
    <div  style={{backgroundImage:`url(${Banner})`}}>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="*" element={<Home/>} />
      </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;