import React from "react";
import Layout from "../components/Layout/Layout";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// import EmailIcon from '@mui/icons-material/Email';
// import CallIcon from '@mui/icons-material/Call';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import '../styles/ContactUsStyles.css'

const Contact = () => {
  return (
    <Layout>
      <Box sx={{
        mt:4, textAlign: "center", p: 2,
        '& h4': { fontWeight: "bold", fontSize: "2rem"},
        '& p': { textAlign: "justify" },
        '@media (max-width:600px)': {
          mt: 0,
          '& h4': { fontSize: "1.5rem" }
        }
      }}>
        <Typography variant="h4" sx={{color: "#0066b2"}}>Contact Us</Typography>
        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, neque assumenda. Excepturi pariatur, quos dolor inventore officia dolorem ad a est quasi iste facilis commodi distinctio rerum similique minus ipsa iure fugiat, consequatur neque rem. </p> */}
      </Box>
      <Box sx={{
        margin: "0 auto", width: "600px",
        '@media (max-width:600px)': {
          width: "300px"
        },
        mb:2
      }}>

        <TableContainer component={Paper}>
          <Table area-aria-label="contact label">
            <TableHead>
              <TableRow>
                <TableCell sx={{ bgcolor: "#0066b2", color: "white" }} align="center">Contact Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                <TextField className="table-row-cell-data" id="outlined-basic" label="Enter your name" variant="outlined" />
                  {/* <SupportAgentIcon sx={{ color: "red", pt: 1 }} /> 1800-000-000 ("tollfree") */}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell >
                <TextField className="table-row-cell-data" id="outlined-basic" label="Mobile No" variant="outlined" />

                  {/* <EmailIcon sx={{ color: "skyblue", pt: 1 }} /> help@gmail.com */}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                <TextField
                  className="table-row-cell-data"
                    id="outlined-basic filled-multiline-static"
                    label="Comment"
                    multiline
                    rows={4}
                    variant="filled"
                  />
                {/* <TextField id="outlined-basic filled-textarea" label="Comment" variant="outlined" multiline/> */}

                  {/* <CallIcon sx={{ color: "green", pt: 1 }} /> 1234567890 */}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell  sx={{textAlign: "center"}}>
                <Button variant="contained">Submit</Button>
                </TableCell>
              </TableRow>
            </TableBody>

          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
};

export default Contact;
