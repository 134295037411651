// import { Button } from "@mui/base";
import React from "react";
// import { Link } from "react-router-dom";
import Layout from "../components/Layout/Layout";
// import Banner from "../images/banner.jpg"
import '../styles/HomeStyles.css'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';


const Home = () => {
  return (
    <Layout>
      <div className="home">
        <div className="header-container">
          <h2>Home District Chemist Association</h2>
          <div className="person-cards-container">

            {/* <div className="person"> */}
            <div className="person-info-inner">
              <div className="person-info">
                <h3>Mohan Ahuja</h3>
                <p>President</p>
                <p>M-9414250422</p>
              </div>
              <div className="person-contact-info-icon">
                <a href='/'>
                  <WhatsAppIcon></WhatsAppIcon>
                </a>
                <a href='/'>
                  <CallIcon></CallIcon>
                </a>
              </div>
            </div>

            <div className="person-info-inner">
              <div className="person-info">
                <h3>Vivek Vijayvargiya</h3>
                <p>Secretary</p>
                <p>M-7230928999</p>
              </div>
              <div className="person-contact-info-icon">
                <a href='/'>
                  <WhatsAppIcon></WhatsAppIcon>
                </a>
                <a href='/'>
                  <CallIcon></CallIcon>
                </a>
              </div>
            </div>

            <div className="person-info-inner">
              <div className="person-info">
                <h3>Govil Garg</h3>
                <p>Joint Secretary</p>
                <p>M-9269332293</p>
              </div>
              <div className="person-contact-info-icon">
                <a href='/'>
                  <WhatsAppIcon></WhatsAppIcon>
                </a>
                <a href='/'>
                  <CallIcon></CallIcon>
                </a>
              </div>
            </div>

            <div className="person-info-inner">
              <div className="person-info">
                <h3>Jaikesh Bhadoria</h3>
                <p>Treasurer</p>
                <p>M-9414424593</p>
              </div>
              <div className="person-contact-info-icon">
                <a href='/'>
                  <WhatsAppIcon></WhatsAppIcon>
                </a>
                <a href='/'>
                  <CallIcon></CallIcon>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
